import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';


@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})

/**
 * Page Contact-Us Component
 */
export class ContactUsComponent implements OnInit {
  name: string = '';
  email: string = '';
  subject: string = '';
  comments: string = '';

    constructor(private modalService: NgbModal, private http: HttpService,
        private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {
        const content: any = {
            title: "Contact Owner - Contact Us",
            description: "Questions? Get In Touch with Us!",
            robots: "index,follow",
            ogType: "website",
            ogUrl: "www.contact-owner.com/contact-us",
            //ogImage: "www.hsa-shop.net/assets/images/mobileapp/app_landing.PNG",
            ogSiteName: "Contact Owner",
            twitterUsername: "",
            viewport: "width=device-width,initial-scale=1.0"
        }

        this.titleService.setTitle(content.title);

        const description: MetaDefinition = {
            name: "description",
            content: content.description
        }

        const robots: MetaDefinition = {
            name: "robots",
            content: content.robots
        }

        const ogType: MetaDefinition = {
            name: "og:type",
            content: content.ogType
        }

        const ogTitle: MetaDefinition = {
            name: "og:title",
            content: content.title
        }

        const ogDescription: MetaDefinition = {
            name: "og:description",
            content: content.description
        }

        /*
        const ogImage: MetaDefinition = {
            name: "og:image",
            content: content.ogImage
        }*/

        const ogUrl: MetaDefinition = {
            name: "og:url",
            content: content.ogUrl
        }

        const ogSiteName: MetaDefinition = {
            name: "og:site_name",
            content: content.ogSiteName
        }

        const twitterTitle: MetaDefinition = {
            name: "twitter:title",
            content: content.title
        }

        const twitterDescription: MetaDefinition = {
            name: "twitter:description",
            content: content.description
        }
        
        const twitterImage: MetaDefinition = {
            name: "twitter:image",
            content: content.ogImage
        }

        const twitterUrl: MetaDefinition = {
            name: "twitter:url",
            content: content.ogUrl
        }

        // <meta name="twitter:site" content="@USERNAME">
        // <meta name="twitter:creator" content="@USERNAME">

        const viewport: MetaDefinition = {
            name: "viewport",
            content: content.viewport
        }


        const keywords: MetaDefinition = {
            name: "keywords",
            content: "find my device, contact us, find my iphone, find my ipad, find my"
        }

        this.metaService
            .addTags([description, robots, ogType, ogTitle,
                ogDescription, ogUrl, ogSiteName, twitterImage,
                twitterTitle, twitterDescription, twitterUrl,
                viewport, keywords]);
    }

    ngOnDestroy(): void {
        this.titleService.setTitle("Contact Owner");
        this.metaService.removeTag("name='description'");
        this.metaService.removeTag("name='robots'");
        this.metaService.removeTag("name='og:type'");
        this.metaService.removeTag("name='og:title'");
        this.metaService.removeTag("name='og:description'");
        this.metaService.removeTag("name='og:image'");
        this.metaService.removeTag("name='og:url'");
        this.metaService.removeTag("name='og:site_name'");
        this.metaService.removeTag("name='twitter:title'");
        this.metaService.removeTag("name='twitter:description'");
        this.metaService.removeTag("name='twitter:image'");
        this.metaService.removeTag("name='twitter:url'");
        this.metaService.removeTag("name='viewport'");
        this.metaService.removeTag("name='keywords'");
    }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  submitContactForm() {
    if(this.email.trim() == '' || this.comments.trim() == ''){
      alert('Please include your email and comments.');
      return;
    }
    let promise = this.http.contactForm(this.name, this.email, this.subject,this.comments).toPromise();
    alert('Thank you for contacting us, we will get back to you soon.');
  }
}
