<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Privacy Policy </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 1st May,
              2022</li>
          </ul>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Overview :</h5>
            <div class="col-md-10 col-md-offset-1">
              <p>
                  Think Discount, LLC is committed to protecting your privacy. This privacy statement
                  describes how Think Discount, LLC collects and uses the personal information you provide
                  on our website (www.contact-owner.com) and applications (Teams Application). It also describes the choices available to you regarding
                  Think Discount, LLC use of your personal information and the steps you can take to access
                  this information and to request that we correct or update it.
              </p>
              <h2>Collection and Use of Personal Information</h2>
              <h3>We may collect the following personal information from you.</h3>
              <p>
                  </p><ul class="list2">
                      <li>Contact information such as name, email address, shipping address, phone number</li>
                      <li>Business Information such as company name, company size, business type</li>
                      <li>
                          Billing Information such as credit card number, expiration date, billing address
                      </li>
                  </ul>
              <p></p>
              <h3>We may collect this information when you</h3>
              <p>
                  </p><ul class="list2">
                      <li>When you use our applications and/or services</li>
                      <li>Contact us to request more information about our products</li>
                      <li>Sign up to receive our whitepapers</li>
                      <li>Set up an account</li>
                      <li>Purchase products</li>
                      <li>Sign up for our newsletter or mailing list</li>
                      <li>Submit a product review</li>
                      <li>
                          Submit a comment and/or inquiry to our community message boards, 'Live Chat' feature
                      </li>
                      <li>Provide us feedback</li>
                  </ul>
              <p></p>
              <h3>We may use this information to</h3>
              <p>
                  </p><ul class="list2">
                      <li>Enchance your experience with our applications and/or services</li>
                      <li>
                          Assess the needs of your business to determine which products best meet those
                          needs
                      </li>
                      <li>Send you requested product information</li>
                      <li>Fulfill your order</li>
                      <li>Send you an order confirmation</li>
                      <li>Show you products you may be interested in</li>
                      <li>Send you a newsletter, whitepaper, or marketing communications</li>
                      <li>Respond to your questions and concerns </li>
                      <li>Assist you with troubleshooting issues and offer support services</li>
                      <li>Improve our Web site, Applications and Services, and marketing efforts</li>
                  </ul>
              <p></p>
              <p>
                  If you provide us personal information about others, or if others give us your
                  information, we will only use that information for the specific reason for which
                  it was provided to us.
              </p>
              <h2>Information Sharing</h2>
              <p>
                  We will share your personal information with third parties only in the ways that
                  are described in this privacy statement. We do not sell, rent, trade, or
                  otherwise share your personal information with third parties other than agents,
                  service providers acting on our behalf for limited purposes. For example, we may
                  share personal information with our agents or service providers to send you
                  email on our behalf. Certain features on our site, such as our 'Live Chat',
                  forum, and shopping cart may be hosted by service providers. In this case, these
                  third parties are authorized to use your personal information only to perform
                  the service they are providing for us, and do not have an independent right to
                  share or use this information.
              </p>
              <p>
                  We reserve the right to disclose your personal information as required by law
                  (e.g., to comply with a subpoena, warrant, court order, or legal process served
                  on our Web site) and when we believe that disclosure is necessary to protect our
                  rights, protect your safety or the safety of others, investigate fraud, and/or
                  respond to a government request.
              </p>
              <p>
                  In the event Think Discount, LLC goes through a business transition, such as
                  a merger, acquisition by another company, or sale of all or a portion of its
                  assets, your personally identifiable information will likely be among the assets
                  transferred. You will be notified via email and/or a prominent notice on our Web
                  site of any such change in ownership or control of your personal information, as
                  well as any choices you may have regarding your personal information.
              </p>
              <h2>Choice/Opt-Out</h2>
              <p>
                  You may opt-out of receiving our newsletter or marketing emails from us by
                  following the unsubscribe instructions included in each newsletter or marketing
                  email or you can contact us at <a href="contactus.html">
                      contactus@thinkdiscountllc.com
                  </a>.
              </p>
              <h2>
                  Cookies and Other Tracking Technologies
              </h2>
              <h3>Cookies</h3>
              <p>
                  Cookies are very small text files that are stored on your computer when you
                  visit certain web pages. We or third parties may use cookies on our site to
              </p>
              <p>
                  </p><ul class="list2">
                      <li>Compile aggregate statistics about our site visitors</li>
                      <li>Analyze which pages our site visitors visit</li>
                      <li>Measure advertising and promotional effectiveness</li>
                      <li>Make it easier for you to navigate our site</li>
                  </ul>
              <p></p>
              <p>
                  We use Google AdSense to serve ads on this site. When you view or click on an
                  ad, a cookie will be set to help better provide advertisements that are of
                  interest to you on this and other websites. You may opt-out of the use of this
                  cookie by visiting Google's Advertising and Privacy page:
                  <a href="http://www.google.com/privacy_ads.html">
                      http://www.google.com/privacy_ads.html
                  </a>.
              </p>
              <h3>Log Files</h3>
              <p>
                  As is true of most websites and applications, we gather certain information automatically and
                  store it in log files. This information may include internet protocol (IP)
                  addresses, browser type, internet service provider (ISP), referring/exit pages,
                  operating system, date/time stamp, and/or page views.
              </p>
              <p>
                  We may use this information, which does not usually identify individual users,
                  to analyze trends, to administer the site, to track users' movements around the
                  site and to gather demographic information about our user base as a whole.
              </p>
              <h3>Web beacons</h3>
              <p>
                  A web beacon is an electronic image placed on a web page that can serve many of
                  the same purposes that cookies serve, for example, recording the pages you visit
                  on our site. We or third parties such may use Web beacons to track the traffic
                  patterns of users from one page to another.
              </p>
              <p>
                  We or our third party service provider may use web beacons in some of our emails
                  to let us know which emails have been opened by recipients or track
                  click-through rates. This allows us to gauge the effectiveness of our
                  communications to customers and our marketing campaigns.
              </p>
              <h2>
                  Correcting and Updating Your Personal Information
              </h2>
              <p>
                  To review and update your personal information to ensure it is accurate, contact
                  us at <a href="contact-us">contactus@thinkdiscountllc.com</a>.
              </p>
              <h2>
                  Links to Other Web Sites
              </h2>
              <p>
                  Our site and applications includes links to other websites whose privacy practices may differ
                  from those of Think Discount, LLC. If you submit personal information to any
                  of those sites, your information is subject to their privacy statements. We
                  encourage you to carefully read the privacy statement of any website you visit.
              </p>
              <h3>Widgets</h3>
              <p>
                  Our Web site includes Widgets, which are interactive mini-programs that run on
                  our site and are designed to provide single-purpose services (e.g. displaying
                  the latest news, opinions, music, etc). These Widgets are hosted by a third
                  party. Personal information, such as your email address, may be collected
                  through the Widget. Cookies may also be set by the Widget to enable certain
                  features of the Widget to function properly. The information collected by this
                  Widget is subject to the Widget's privacy policy. Think Discount, LLC is not
                  responsible for personal information you choose to provide through the Widget.
              </p>
              <h3>Message Boards, Forums, Community Groups </h3>
              <p>
                  Whenever you choose to voluntarily post general and/or personal information in
                  public areas, such as our message boards, forums, community groups, this
                  information can be accessed by the public and can in turn be used by to send you
                  unsolicited communications. Therefore, Think Discount, LLC recommends that
                  you exercise discretion when deciding what information you choose to make public
                  on the Internet.
              </p>
              <h2>
                  Security
              </h2>
              <p>
                  We follow generally accepted industry standards to protect the personal
                  information submitted to us, both during transmission and once we receive it. No
                  method of transmission over the Internet, or method of electronic storage, is
                  100% secure, however. Therefore, while we strive to use commercially acceptable
                  means to protect your personal information, we cannot guarantee its absolute
                  security.
              </p>
              <p>
                  The security of your personal information is important to us. When you enter
                  sensitive information (such as credit card number) on our or our checkout
                  provider's order form, that information is encrypted using secure socket layer
                  technology (SSL).
              </p>
              <p>
                  If you have any questions about security on our Web site, you can contact us at
                  <a href="contact-us">contactus@thinkdiscountllc.com</a>.
              </p>
              <h2>Services/Applications/Platforms</h2>
            

              <p>
                  We host our solutions in Microsoft Azure. We run our service offerings using Serverless technologies.
              </p>

              <p>
                  Our password manager solutions uses a patent pending solution that allows the password itself to NOT be stored in the password manager for maximum security. At your choice, if you choose to store sensitive information in the Password Manager (such as, but not limited to, password, pin, record name etc.), these information is encrypted on the client-side using AES-256 encryption before sending across the network. We have no access to the key to decrypt your data. At rest, your information is stored in an encrypted format.
              </p>
              <h2>
                  Notification of Privacy Statement Changes
              </h2>
              <p>
                  We may update this policy from time to time as our information practices are
                  modified or changed. If we make any material changes to this policy that affect
                  your personal information, we will notify you by email (sent to the e-mail
                  address specified in your account) or by means of a notice on this Site prior to
                  the change. We encourage you to periodically review this page for the latest
                  information on our privacy practices.
              </p>
              <h2>
                  Contact Information
              </h2>
              <p>You can contact us by email us at the address below.</p>
              <p>Think Discount, LLC</p>
              <p><a href="contact-us">contactus@thinkdiscountllc.com</a></p>

          </div>

            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->