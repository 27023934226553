import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SecuresharingService {

  constructor(private httpService: HttpService) { }
 
 
  public async expireInformation(key: string): Promise<any>{
    let pr = new Promise<string>(async (resolve,reject) =>{    
      let body = {"Key": key};
      await this.httpService.postToFunction('InstantPassForceExpired', body).subscribe(s=>{
        resolve((<any>s));
      });
    });

    return pr;
  }
  public async getInformation(key: string): Promise<any>{

    let pr = new Promise<string>(async (resolve,reject) =>{    
      let body = {"Key": key};
      await this.httpService.postToFunction('InstantPassGetEntry', body).subscribe(s=>{
        resolve((<any>s));
      });
    });

    return pr;
  }
}
