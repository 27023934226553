import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpService } from 'src/app/services/http.service';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-owner',
    templateUrl: './contact-owner.component.html',
    styleUrls: ['./contact-owner.component.css']
})

/**
 * Contact Owner Component
 */
export class ContactOwnerComponent implements OnInit {
    sentMessage: string = '';
    code: string = '';
    firstname: string = '';
    lastname: string = '';
    email: string = '';
    phone: string = '';
    message: string = 'Hello! I found your device. Please contact me for more details.';
    errorText: string = '';
    constructor(private route: ActivatedRoute, private httpService: HttpService,
                private titleService: Title, private metaService: Meta) {
        this.route.params.subscribe(params => {
            if (params['code']) this.code = params['code'];
        });
        // Warm Up
        this.httpService.findMyDevice('this.firstname','this.lastname', 'this.phone', 'this.email', 'this.code', 'this.message', 'WarmUp').toPromise();
  
    }

    public async sendMessage(){
      this.sentMessage = '';
      if(this.validate()){
          let result: any = await this.httpService.findMyDevice(this.firstname,this.lastname, this.phone, this.email, this.code, this.message, '').toPromise();
          if(result.code=='true'){
                this.sentMessage = "Message Sent";
                this.errorText = '';
          } else {
            this.errorText = 'Error Sending Message, please try again soon.';
          } 

            }
    }

    validate(): boolean{
      let result: boolean = false;   
      
      if(this.code.trim() == "") {
        this.errorText = "Code is required.";
        return false;
      }
      if(this.firstname.trim() == "") {
        this.errorText = "First name is required.";
        return false;
      }
      if(this.email.trim() == "") {
        this.errorText = "Email is required.";
        return false;
      }
      if(this.message.trim() == "") {
        this.errorText = "Message is required.";
        return false;
      }
      return true;
    }

  /**
   * Testimonial Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

    ngOnInit(): void {
        const content: any = {
            title: "Contact Owner App - Contact an Owner",
            description: "Found a lost device? Contact Device Owner",
            robots: "index,follow",
            ogType: "website",
            ogUrl: "www.contact-owner.com/d",
            //ogImage: "www.hsa-shop.net/assets/images/mobileapp/app_landing.PNG",
            ogSiteName: "Contact Owner",
            twitterUsername: "",
            viewport: "width=device-width,initial-scale=1.0"
        }

        this.titleService.setTitle(content.title);

        const description: MetaDefinition = {
            name: "description",
            content: content.description
        }

        const robots: MetaDefinition = {
            name: "robots",
            content: content.robots
        }

        const ogType: MetaDefinition = {
            name: "og:type",
            content: content.ogType
        }

        const ogTitle: MetaDefinition = {
            name: "og:title",
            content: content.title
        }

        const ogDescription: MetaDefinition = {
            name: "og:description",
            content: content.description
        }

        /*
        const ogImage: MetaDefinition = {
            name: "og:image",
            content: content.ogImage
        }*/

        const ogUrl: MetaDefinition = {
            name: "og:url",
            content: content.ogUrl
        }

        const ogSiteName: MetaDefinition = {
            name: "og:site_name",
            content: content.ogSiteName
        }

        const twitterTitle: MetaDefinition = {
            name: "twitter:title",
            content: content.title
        }

        const twitterDescription: MetaDefinition = {
            name: "twitter:description",
            content: content.description
        }

        /*
        const twitterImage: MetaDefinition = {
            name: "twitter:image",
            content: content.ogImage
        }*/

        const twitterUrl: MetaDefinition = {
            name: "twitter:url",
            content: content.ogUrl
        }

        // <meta name="twitter:site" content="@USERNAME">
        // <meta name="twitter:creator" content="@USERNAME">

        const viewport: MetaDefinition = {
            name: "viewport",
            content: content.viewport
        }


        const keywords: MetaDefinition = {
            name: "keywords",
            content: "find my, find my device, find my iphone, find my ipad"
        }

        this.metaService
            .addTags([description, robots, ogType, ogTitle,
                ogDescription, ogUrl, ogSiteName,
                twitterTitle, twitterDescription, twitterUrl,
                viewport, keywords]);
  }

    ngOnDestroy(): void {
        this.titleService.setTitle("Contact Owner");
        this.metaService.removeTag("name='description'");
        this.metaService.removeTag("name='robots'");
        this.metaService.removeTag("name='og:type'");
        this.metaService.removeTag("name='og:title'");
        this.metaService.removeTag("name='og:description'");
        this.metaService.removeTag("name='og:image'");
        this.metaService.removeTag("name='og:url'");
        this.metaService.removeTag("name='og:site_name'");
        this.metaService.removeTag("name='twitter:title'");
        this.metaService.removeTag("name='twitter:description'");
        this.metaService.removeTag("name='twitter:image'");
        this.metaService.removeTag("name='twitter:url'");
        this.metaService.removeTag("name='viewport'");
        this.metaService.removeTag("name='keywords'");
    }
}
