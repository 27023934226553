<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
  <div class="container">
      <form>
          <div class="row align-items-center">

              <div class="col-lg-5 offset-lg-1 col-md-7 order-1 order-md-1">
                  <div class="title-heading mt-4">
                      <h1 class="display-4 fw-normal mb-3">Contact Device Owner</h1>
                      <br />
                      <h4 class="text-primary">Where is Device Owner Contact Code?</h4>
                      <p class="para-desc text-muted">
                          Suppose that the Device Owner Contact Url is <span class="fw-bold">https://www.contact-owner.com/d/447NSFUW9EH6</span>
                          <br /><br />
                          The Device Owner Code will be <span class="fw-bold">447NSFUW9EH6</span>
                      </p>
                      <div class="text-center subcribe-form mt-4 pt-2">

                          <input [(ngModel)]="code" type="text" id="code" name="code" class="h4 border border-primary bg-white rounded-lg text-primary fw-bold" style="opacity: 0.85;" required
                                 placeholder="Enter Device Owner Code">
                          <!--<button type="submit" class="btn btn-pills btn-primary">Get Started</button>-->
                      </div>
                  </div>
              </div>
              <!--end col-->

              <div class="col-lg-6 col-md-5 order-2 order-md-2 mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div class="bg-white shadow rounded position-relative overflow-hidden">


                      <div class="card-body">
                          <div class="row">
                              <div class="col-md-12">
                                  <div class="mb-3">
                                      <label class="form-label">Device Owner Code <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                          <input type="text" class="form-control ps-5" placeholder="Device Owner Code" [(ngModel)]="code" name="code" id="code" required="">
                                      </div>
                                  </div>
                              </div>
                              <!--end col-->
                              <div class="col-md-6">
                                  <div class="mb-3">
                                      <label class="form-label">First Name <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                          <input type="text" class="form-control ps-5" placeholder="First Name" [(ngModel)]="firstname" name="firstname" id="firstname" required="">
                                      </div>
                                  </div>
                              </div>
                              <!--end col-->
                              <div class="col-md-6">
                                  <div class="mb-3">
                                      <label class="form-label">Last Name <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                          <input type="text" class="form-control ps-5" placeholder="Last Name" [(ngModel)]="lastname" name="lastname" id="lastname" required="">
                                      </div>
                                  </div>
                              </div>
                              <!--end col-->
                              <div class="col-md-6">
                                  <div class="mb-3">
                                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                          <input type="email" class="form-control ps-5" placeholder="Email" [(ngModel)]="email" name="email" id="email" required="">
                                      </div>
                                  </div>
                              </div>
                              <!--end col-->
                              <div class="col-md-6">
                                  <div class="mb-3">
                                      <label class="form-label">Your Phone <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                          <input type="email" class="form-control ps-5" placeholder="Phone" [(ngModel)]="phone" name="phone" id="phone" required="">
                                      </div>
                                  </div>
                              </div>
                              <!--end col-->
                              <div class="col-12">
                                  <div class="mb-3">
                                      <label class="form-label">Message <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                                          <textarea [(ngModel)]="message" name="message" id="message" rows="5" class="form-control ps-5"
                                                    placeholder="Message To Device Owner: {{code}}"
                                                    required>                                            
                                          </textarea>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-12">
                                  <div class="d-grid">
                                      <span class="error-text">{{errorText}}</span>
                                      <button class="btn btn-primary" (click)="sendMessage()">Send Message</button>

                                      <span class="sent-text">{{sentMessage}}</span>
                                  </div>
                              </div>
                              <!--end col-->
                          </div>
                          <!--end row-->
                      </div>
                      <!--end form-->


                  </div>
              </div>
              <!--end col-->

          </div>
      </form>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->