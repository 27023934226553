import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor() { }


  public copyToClipboard(text) {
    function selectElementText(element) {
        if ((<any>document).selection) {
            var range = (<any>document.body).createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            var range = <any>document.createRange();
            range.selectNode(element);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
    }
    var element = document.createElement('DIV');
    element.textContent = text;
    document.body.appendChild(element);
    selectElementText(element);
    document.execCommand('copy');
    element.remove();
    //this.toastService.showShortSuccess("Copied to clipboard");
}
}
