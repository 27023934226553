import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tutorials',
    templateUrl: './tutorials.component.html',
    styleUrls: ['./tutorials.component.css']
})

/**
 * Helpcenter Tutorials Component
 */
export class TutorialsComponent implements OnInit {
  hideFooter = true;
  constructor() { }

  ngOnInit(): void {
  }

}
