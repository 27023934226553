<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading">
                    <h3>Think You Might Lose Your Phone? <br />Let people contact you with </h3>
                    <h1 class="heading"><span class="text-primary">Contact Owner</span></h1>
                    <p class="para-desc text-muted">Place your contact link in your device's Today View. No need to attach your private information to your device.</p>
                    <h6 class="fw-bold">Why take the chance? Your device is valuable to you.</h6>
                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="text-md-end text-center ms-lg-4">
                    <img src="assets/images/mobileapp/link_qr_code.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Vision -->
<section class="section bg-light" id="mission">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <p class="display-6"><b>The current device locator solution has <span class="text-primary">privacy</span>&nbsp;<span class="bg-primary text-white">&nbsp;concerns&nbsp;</span>, is dependent on the device <span class="text-primary">battery life</span> and having the device range be <span class="text-primary">within the network</span> for it to work.</b></p>
                    <br/>
                    <p class="display-6"><b>It is important that we have a solution that <span class="bg-primary text-white">&nbsp;protects&nbsp;</span> the device owner's <span class="text-primary">privacy, independent of the device battery life and network range</span>.</b></p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>


<!-- How This Works?-->
<section class="section bg-light" id="howthisworks">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">How <span class="text-primary">Contact Owner</span> work?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        We enable people to reach out to you if they find your lost device.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-edit d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Subscribe To Service</h5>
                        <p class="text-muted mb-0">
                            Get started by subscribing to Contact Owner subscription plan.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-link d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Register Device</h5>
                        <p class="text-muted mb-0">
                            Register your device with Contact Owner. We will create your contact link.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-chat-bubble-user d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Add Widget and Sticker</h5>
                        <p class="text-muted mb-0">
                            Add Contact Owner widget to your Today View and/or place our contact sticker on your device.
                            <br/><span class="fw-bold">** Stickers coming soon</span>
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

</section>
<!--end section-->
<!-- Features Start -->
<section class="section" id="features">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Learn More About <span class="text-primary">Contact Owner</span> Features</h4>
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->

        <div class="row justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="row mt-4 pt-2">
                    <div class="col-md-4 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <i-feather name="link" class="fea icon-ex-md text-primary"></i-feather>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Secure Contact Link</h4>
                                <p class="text-muted para mb-0">
                                    We will provide a contact link for people to reach out to you. The messages will be forwarded to your email address.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <i-feather name="align-center" class="fea icon-ex-md text-primary"></i-feather>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Today View Contact Widget</h4>
                                <p class="text-muted para mb-0">
                                    Our mobile application lets you place a contact link in your device's Today View so people can reach out to you when they locate your lost device.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <i-feather name="battery-charging" class="fea icon-ex-md text-primary"></i-feather>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Wifi & Battery Independent</h4>
                                <p class="text-muted para mb-0">
                                    People can still contact you even if your device is dead! Place our contact link anywhere on the device in additional to the widget.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Easy To Use</h4>
                                <p class="text-muted para mb-0">
                                    #1 Subscribe To Service
                                    <br />#2 Register Device
                                    <br />#3 Add Widget and Sticker
                                    <br /><span class="fw-bold">** Stickers coming soon</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <i-feather name="shield" class="fea icon-ex-md text-primary"></i-feather>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Personal Privacy</h4>
                                <p class="text-muted para mb-0">
                                    No need to display your contact information on your phone to randome strangers. Just display our contact link and a message for people to contact you via our contact link.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <i-feather name="message-square" class="fea icon-ex-md text-primary"></i-feather>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Contact Link Stickers</h4>
                                <p class="text-muted para mb-0">
                                    Print out our contact link or order stickers from us to place our contact link on your device.
                                    <br /><span class="fw-bold">** Stickers coming soon</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->


                </div>
                <!--end row-->
            </div>
            <!--end col-->
            <!--
            <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
                <img src="assets/images/app/feature.png" class="img-fluid" alt="">
            </div> -->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->
<!-- Media Password Start -->
<section class="section bg-light" id="why">
    <div class="container">
       
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 mt-4 pt-2">
                <img src="assets/images/mobileapp/add_widget.png" class="img-fluid mx-auto d-block" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 pt-2">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Why Use <span class="text-primary">Contact Owner</span>?</h4>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0 pb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>The current device locator solution has <span class="text-primary">privacy concerns</span>, is dependent on the device <span class="text-primary">battery life</span> and having the device range be <span class="text-primary">within the network</span> for it to work.
                        </li>
                        <li class="mb-0 pb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>
                            It is important that we have a solution that <span class="text-primary">protects</span> the device owner's <span class="text-primary">privacy, independent of the device battery life and network range</span>.
                        </li>
                    </ul>
                    <div class="mt-0">
                        <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                    </div>

                </div>
                <br/><br/>
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Why <span class="text-primary">Current Solutions</span> Is Not Enough</h4>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0 pb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-times-circle align-middle"></i>
                            </span>Chances are you need to have your phone battery on and within the network range to be able to locate your phone.
                        </li>
                        <li class="mb-0 pb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-times-circle align-middle"></i>
                            </span>Chances are you have only one phone number for yourself and the very same phone number is attached to your lost device.
                        </li>
                        <li class="mb-0 pb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-times-circle align-middle"></i>
                            </span>Even if you have the ability to update messages in the lock screen, you will still have to update the message after the phone is lost to update the contact phone number or expose your email address.
                        </li>
                        <li class="mb-0 pb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-times-circle align-middle"></i>
                            </span>Device owner must provide either another phone number or personal contact information such as email or families/friends phone number for people to reach them.
                        </li>
                    </ul>

                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

</section>
<!--end section-->
<!-- Media Password End -->
<!-- Showcase Start -->
<section class="section bg-light" id="setup">


    <div class="container mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Setup <span class="text-primary">Contact Owner</span> In 3-Steps</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">
                        In three simple steps, you can attach your contact link to your device Today View without providing private information.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class=" mt-4 pt-2 text-center">
                <ul ngbNav #nav="ngbNav"
                    class="nav-pills nav-justified flex-column flex-sm-row rounded col-lg-8 col-md-12 offset-md-2">
                    <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Step 1</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="assets/images/mobileapp/step1.png" class="img-fluid mx-auto d-block" alt="Step 1 - Subscribe To Service">
                                </div>
                                <!--end col-->

                                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div class="section-title text-md-start">
                                        <h4 class="title mb-4">
                                            <i class="uil uil-angle-double-right text-primary"></i><span class="text-primary">Subscribe</span> To Service
                                        </h4>
                                        <p class="text-muted">
                                            Get started by subscribing to Contact Owner service
                                        </p>
                                        <ul class="list-unstyled text-muted">
                                            <li class="mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Purchase Contact Owner Subscription
                                            </li>
                                            <li class="mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Pricing Information is <a href="app#pricing" class="fw-bold text-primary">here</a>
                                            </li>
                                        </ul>
                                        <div class="mt-4">
                                            <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Step 2</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="assets/images/mobileapp/step2.png" class="img-fluid mx-auto d-block" alt="Step 2 - Register Device">
                                </div>
                                <!--end col-->

                                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div class="section-title text-md-start">
                                        <h4 class="title mb-4">
                                            <i class="uil uil-angle-double-right text-primary"></i><span class="text-primary">Register</span> Device
                                        </h4>
                                        <p class="text-muted">
                                            Setup your contact information. Let us know how we can deliver messages to you.
                                        </p>
                                        <ul class="list-unstyled text-muted">
                                            <li class="mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Fill our your contact information
                                            </li>
                                        </ul>
                                        <div class="mt-4">
                                            <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Step 3</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="assets/images/mobileapp/step3.png" class="img-fluid mx-auto d-block" alt="Step 3 - Add Widget and Stickers">
                                </div>
                                <!--end col-->

                                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div class="section-title text-md-start">
                                        <h4 class="title mb-4">
                                            <i class="uil uil-angle-double-right text-primary"></i>Add <span class="text-primary">Widget</span> and <span class="text-primary">Sticker</span>
                                        </h4>
                                        <p class="text-muted">
                                            Place our contact link on your device where people can find it
                                        </p>
                                        <ul class="list-unstyled text-muted">
                                            <li class="mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Add our widget to Today View
                                            </li>
                                            <li class="mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Print out or order contack link stickers from us and attach that to your device
                                            </li>
                                        </ul>
                                        <span class="text-muted fw-bold">** Stickers coming soon</span>
                                        <br/>
                                        <div class="mt-4">
                                            <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
            </div>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Showcase End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Price Start -->
<section class="section" id="pricing">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4"><span class="text-primary">Instant Pass</span> Subscription Plan</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">
                        Our Subscription Plan is billed on an <span class="text-primary fw-bold">Annual Basis</span>.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-simple-pricing [simple_pricingData]="subscriptionPrice"></app-simple-pricing>


        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Price End -->
<!-- Testi n Download cta start -->
<section class="section" id="downloads">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Install <span class="text-primary">Contact Owner</span> today!</h4>
                    <h5 class="text-muted mx-auto">
                        Why take the chance? Do not wait until you lose your device.
                    </h5>
                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App Store</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
      
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Testi n Download cta End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->