import { Component, OnInit } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-findmydevice',
    templateUrl: './findmydevice.component.html',
    styleUrls: ['./findmydevice.component.css']
})

/***
 * Apps Component
 */
export class FindMyDeviceComponent implements OnInit {

    constructor(private titleService: Title, private metaService: Meta) { }

  /***
   * Client Review Owl Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  /**
   * Simple Pricing Data
   */

    oneTimePrice = [
        {
            title: "Free",
            price: 1.99,
            list: ["Device Vault Full Access", "Cloud Vault Free Trial", "Unlimited Video Passwords", "Unlimited Picture Passwords"],
            btn: "Buy Now"
        },
        {
            warning: "Best",
            title: "Starter",
            price: 7.99,
            list: ["Device Vault Full Access", "Cloud Vault Full Access", "Secure Sharing", "Unlimited Video Passwords", "Unlimited Picture Passwords"],
            btn: "Get Started"
        },
        {
            title: "Professional",
            price: 14.99,
            list: ["Full Access", "Enhanced Security", "Source Files", "1 Domain Free"],
            btn: "Try It Now"
        }
    ];

    subscriptionPrice = [
    {
      title: "Annual Plan",
      price: 0.50,
            list: ["Device Owner Privacy Protection", "Device Owner Contact Form", "Today View Widget", "Sharable Contact Link", "Printable File for Contact Link Stickers"],
      btn: "Buy Now"
    }
  ];

    ngOnInit(): void {
        const content: any = {
            title: "Contact Owner - Product",
            description: "Learn more about Contact Owner Product, Features, Pricing and Why You Want to Use our Application",
            robots: "index,follow",
            ogType: "website",
            ogUrl: "www.contact-owner.com/app",
            //ogImage: "www.hsa-shop.net/assets/images/mobileapp/app_landing.PNG",
            ogSiteName: "Contact Owner",
            twitterUsername: "",
            viewport: "width=device-width,initial-scale=1.0"
        }

        this.titleService.setTitle(content.title);

        const description: MetaDefinition = {
            name: "description",
            content: content.description
        }

        const robots: MetaDefinition = {
            name: "robots",
            content: content.robots
        }

        const ogType: MetaDefinition = {
            name: "og:type",
            content: content.ogType
        }

        const ogTitle: MetaDefinition = {
            name: "og:title",
            content: content.title
        }

        const ogDescription: MetaDefinition = {
            name: "og:description",
            content: content.description
        }

        /*
        const ogImage: MetaDefinition = {
            name: "og:image",
            content: content.ogImage
        }*/

        const ogUrl: MetaDefinition = {
            name: "og:url",
            content: content.ogUrl
        }

        const ogSiteName: MetaDefinition = {
            name: "og:site_name",
            content: content.ogSiteName
        }

        const twitterTitle: MetaDefinition = {
            name: "twitter:title",
            content: content.title
        }

        const twitterDescription: MetaDefinition = {
            name: "twitter:description",
            content: content.description
        }

        /*
        const twitterImage: MetaDefinition = {
            name: "twitter:image",
            content: content.ogImage
        }*/

        const twitterUrl: MetaDefinition = {
            name: "twitter:url",
            content: content.ogUrl
        }

        // <meta name="twitter:site" content="@USERNAME">
        // <meta name="twitter:creator" content="@USERNAME">

        const viewport: MetaDefinition = {
            name: "viewport",
            content: content.viewport
        }


        const keywords: MetaDefinition = {
            name: "keywords",
            content: "find my, find my device, Contact Owner, find my device, find my iphone, find my ipad"
        }

        this.metaService
            .addTags([description, robots, ogType, ogTitle,
                ogDescription, ogUrl, ogSiteName,
                twitterTitle, twitterDescription, twitterUrl,
                viewport, keywords]);
    }

    ngOnDestroy(): void {
        this.titleService.setTitle("Contact Owner");
        this.metaService.removeTag("name='description'");
        this.metaService.removeTag("name='robots'");
        this.metaService.removeTag("name='og:type'");
        this.metaService.removeTag("name='og:title'");
        this.metaService.removeTag("name='og:description'");
        this.metaService.removeTag("name='og:image'");
        this.metaService.removeTag("name='og:url'");
        this.metaService.removeTag("name='og:site_name'");
        this.metaService.removeTag("name='twitter:title'");
        this.metaService.removeTag("name='twitter:description'");
        this.metaService.removeTag("name='twitter:image'");
        this.metaService.removeTag("name='twitter:url'");
        this.metaService.removeTag("name='viewport'");
        this.metaService.removeTag("name='keywords'");
    }
}
