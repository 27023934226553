import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { SecuresharingService } from 'src/app/shared/services/securesharing.service';

@Component({
  selector: 'app-sharing-app',
  templateUrl: './sharing-app.component.html',
  styleUrls: ['./sharing-app.component.css']
})

/***
 * Landing Foue Component
 */
export class SharingAppComponent implements OnInit {

  /**
   * Pricing Data
   */
  pricingData = [
    {
      title: "Free",
      price: 0,
      list: ["Full Access", "Source Files"],
      btn: "Buy Now"
    },
    {
      warning: "Best",
      title: "Starter",
      price: 39,
      list: ["Full Access", "Source Files", "Free Appointments"],
      btn: "Get Started"
    },
    {
      title: "Professional",
      price: 59,
      list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security"],
      btn: "Try It Now"
    },
    {
      title: "Ultimate",
      price: 79,
      list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security", "Free Installment"],
      btn: "Started Now"
    }
  ];

  /**
   * Blog Data
   */
  blogData = [
    {
      image: "assets/images/blog/01.jpg",
      title: "Design your apps in your own way",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/02.jpg",
      title: "How apps is changing the IT world",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/03.jpg",
      title: "Smartest Applications for Business",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    }
  ];
  locked = true;
  privateInformation = 'secret information';
  title = 'wifi password';
  copyToClipboardText = '';
  public fragment: string;
  public isExpired = true;
  public loaded = false;
  public key: string;
  constructor(private modalService: NgbModal,
    private securesharingService: SecuresharingService,
    private clipboardService: ClipboardService) { }


  public forceExpire() {
    let hash = window.location.hash;
    if (hash && hash.indexOf("#") > -1) {
      let key = hash.replace('#', '');
      this.securesharingService.expireInformation(key).then(r => {
        this.processHashForSecureSharing();

      }).catch(c => {

      });
    }
  }
  
  private processHashForSecureSharing() {
    let hash = window.location.hash;
    if (hash && hash.indexOf("#") > -1) {
      let key = hash.replace('#', '');
      this.loaded = false;

      this.securesharingService.getInformation(key).then(r => {
        if (r.value == null) {
          this.isExpired = true;
          
        }
        else {
          let values = JSON.parse(r.value);
          this.title = values.Label;
          this.privateInformation = values.Message;
          this.isExpired = false;
        }
        this.loaded = true;
      }).catch(c => {
        this.isExpired = true;
        this.loaded = true;
      });
    }
  }


  ngOnInit(): void {
    this.processHashForSecureSharing();
  }

  copyToClipboard(): void {
    this.clipboardService.copyToClipboard(this.privateInformation);
    this.copyToClipboardText = 'Copied to clipboard';
  }

  lock(): void {
    this.locked = true;
  }
  unlock(): void {
    this.locked = false;
  }
  /**
 * Open modal for show the video
 * @param content content of modal
 */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
