import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  //http://localhost:7035/api/ProductSearchEvent
  private functionsUrl: string = 'https://findmydeviceapiprod.azurewebsites.net/api/';// 'http://localhost:7071/api/';;// 'https://findmydeviceapiprod.azurewebsites.net/api/';// 'http://localhost:7035/api/';

  constructor(private http: HttpClient) { }
 
  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public buildFunctionsUrl(location: string): string {
    return this.functionsUrl + location;
  }

  public postToFunction(location: string, body: any, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    //let options = this.jwt();
    return this.http.post(this.buildFunctionsUrl(location + queryString), body);//, options);
  }

  public getToFunction(location: string, body: any, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    return this.http.get(this.buildFunctionsUrl(location + queryString));
  }

  // local storage

  public setStorageItem(itemName: string, value: string) {
    localStorage.setItem(itemName, value);
  }

  public getStorageItem(itemName: string): string {
    return localStorage.getItem(itemName);
  }

  public contactForm(name: string, email: string, subject: string, comments: string) {
    let body: any = {
      name: name,
      subject: subject,
      comments: comments,
      email: email,
    };
    return this.postToFunction('contactForm', body);
  }
  public findMyDevice(firstName: string, lastName:string, phone: string, email: string, code: string, message: string, warmUp: string) {
    let body: any = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      message: message,
      code: code,
      warmUp: warmUp
    };
    return this.postToFunction('ContactOwner', body);
  }
  
}
