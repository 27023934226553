<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="/" class="logo-footer">
                            <img src="assets/images/logo-dark.png" height="40" alt="Contact Owner Logo">
                        </a>
                        <p class="mt-4">
                            We want to enable people to do the right thing. Losing your device can be stressful. You never know when its going to happen.
                            <br /><br />
                            Contact Owner is a solution that lets people contact the device owner without revealing their private information. Your privacy is our concern.
                            <br /><br />
                            Download Contact Owner today!
                        </p>
                        <div class="mt-4">
                            <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        </div>
                        <!-- <ul class="list-unstyled social-icon social mb-0 mt-4">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                </a></li>
        </ul> -->
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Our App</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/app" fragment="product" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Product
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="mission" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Mission
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="howthisworks" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    How This Works
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="features" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Features
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="why" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Why Use Us
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="setup" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    How To Setup
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="pricing" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Pricing
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="downloads" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i> Downloads
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <!--    <h5 class="text-light footer-head">Help Center</h5>
        <ul class="list-unstyled footer-list mt-4">
            <li>
                <a routerLink="/help-center" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    <span class="bg-highlight">&nbsp;Help Center&nbsp;</span>
                </a>
            </li>
            <li>
                <a routerLink="/tutorials" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    <span class="bg-highlight">&nbsp;Tutorials&nbsp;</span>
                </a>
            </li>
            <li>
                <a routerLink="/faqs" class="text-muted">
                    <i class="uil uil-angle-right-b me-1"></i>
                    FAQs
                </a>
            </li>
            <li>
                <a routerLink="/contact-us" class="text-muted">
                    <i class="uil uil-angle-right-b me-1"></i>
                    Submit A Request
                </a>
            </li>
        </ul>
        <br /><br />-->
                        <h5 class="text-dark footer-head">Resources</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/about-us" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a routerLink="/terms-of-use" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Terms of Use
                                </a>
                            </li>
                            <li>
                                <a routerLink="/privacy-policy" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact-us" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Contact an Owner</h5>
                        <p class="mt-4">Locate a lost device?</p>
                        <a routerLink="/d" class="text-muted">
                            <i class="uil uil-angle-right-b me-1"></i>
                            Contact an Owner
                        </a>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="/" class="logo-footer">
                            <img src="assets/images/logo-light.png" alt="Contact Owner Logo" height="40">
                        </a>
                        <p class="mt-4">
                            We want to enable people to do the right thing. Losing your device can be stressful. You never know when its going to happen.
                            <br /><br />
                            Contact Owner app is a solution that lets people contact the device owner without revealing their private information. Your privacy is our concern.
                            <br /><br />
                            Download Contact Owner app today!
                        </p>
                        <div class="mt-4">
                            <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        </div>
                        <!-- <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                </a></li>
        </ul> -->
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Our App</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/app" fragment="product" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Product&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="mission" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Mission&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="howthisworks" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;How This Works&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="features" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Features&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="why" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Why Use Us&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="setup" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;How To Setup&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="pricing" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Pricing&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/app" fragment="downloads" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Downloads&nbsp;</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <!--     <h5 class="text-light footer-head">Help Center</h5>
        <ul class="list-unstyled footer-list mt-4">
            <li>
                <a routerLink="/help-center" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    <span class="bg-highlight">&nbsp;Help Center&nbsp;</span>
                </a>
            </li>
            <li>
                <a routerLink="/tutorials" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    <span class="bg-highlight">&nbsp;Tutorials&nbsp;</span>
                </a>
            </li>
            <li>
                <a routerLink="/faqs" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    <span class="bg-highlight">&nbsp;FAQs&nbsp;</span>
                </a>
            </li>
            <li>
                <a routerLink="/contact-us" class="text-foot">
                    <i class="uil uil-angle-right-b me-1"></i>
                    <span class="bg-highlight">&nbsp;Submit A Request&nbsp;</span>
                </a>
            </li>
        </ul>
        <br/><br/>-->
                        <h5 class="text-light footer-head">Resources</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/about-us" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;About Us&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/terms-of-use" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Terms of Use&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/privacy-policy" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Privacy Policy&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact-us" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Contact Us&nbsp;</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->

                   
                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Contact an Owner</h5>
                        <p class="mt-4">Located a lost device?</p>
                        <a routerLink="/d" class="text-foot">
                            <i class="uil uil-angle-right-b me-1"></i>
                            <span class="bg-highlight">&nbsp;Contact an Owner&nbsp;</span>
                        </a>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </ng-template>
</div>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">©
                        <script type="text/javascript" id="www-widgetapi-script"
                            src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                            async=""></script>
                        <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script> {{year}}
                        Contact Owner. Design with <i class="mdi mdi-heart text-danger"></i> by <a
                            href="javascript:void(0);" class="text-reset">Think Discount, LLC</a>. 
                    </p>
                </div>
            </div>
            <!--end col-->

            <!--
            <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul class="list-unstyled text-sm-end mb-0">
                    <li class="list-inline-item"><a href="javascript:void(0)"><img
                                src="assets/images/payments/american-ex.png" class="avatar avatar-ex-sm"
                                title="American Express" alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/discover.png" class="avatar avatar-ex-sm" title="Discover"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/master-card.png" class="avatar avatar-ex-sm"
                                title="Master Card" alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/paypal.png" class="avatar avatar-ex-sm" title="Paypal"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/visa.png" class="avatar avatar-ex-sm" title="Visa"
                                alt=""></a></li>
                </ul>
            </div>
                -->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->