import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
//import 'rxjs/add/operator/map';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private apiUrl: string = 'https://instantpassapidev.azurewebsites.net/api/';//'http://10.0.0.110:5143/api/'; //'https://api.dbit.io/api/'
  private functionsUrl: string = 'https://instantpassapidev.azurewebsites.net/api/';
  constructor(private http: HttpClient) { }

  public getApiUrl(): string {
    return this.apiUrl;
  }

  public buildServiceUrl(location: string): string {
    return this.apiUrl + location;
  }

  public buildFunctionsUrl(location: string): string {
    return this.functionsUrl + location;
  }

  // Optional params?: string param
  public getDirect(location: string, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    //return this.Http.get(this.BuildServiceUrl(location + queryString), this.optionsWithCredentials());
    return this.http.get(location + queryString);
  }

  public getDirectText(location: string, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;

    //let headers = new HttpHeaders({ 'Content-Type': 'application/text' }); // ... Set content type to JSON
    //let options = new RequestOptions({ headers: headers, withCredentials: true }); // Create a request option

    //return this.Http.get(this.BuildServiceUrl(location + queryString), this.optionsWithCredentials());
    return this.http.get(location + queryString, { responseType: 'text' });//, { headers: headers});
  }
  ;
  public get(location: string, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    //return this.Http.get(this.BuildServiceUrl(location + queryString), this.optionsWithCredentials());
    return this.http.get(this.buildServiceUrl(location + queryString));
  }
  public post(location: string, body: any, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    //let headers = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
    //let options = new RequestOptions({ headers: headers, withCredentials: true }); // Create a request option

    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    return this.http.post(this.buildServiceUrl(location + queryString), body);
  }
  public post2FA(location: string, body: any, token: string, queryStringParams?: string): Rx.Observable<Object> {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var queryString: string = "";
    // // let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token, "Cookie": "Identity.TwoFactorUserId=" + token + ";" }); // {"Identity.TwoFactorUserId": "Identity.TwoFactorUserId=" + token + ";"}); // ... Set content type to JSON
    // // let options = new RequestOptions({ headers: headers, withCredentials: true } as RequestOptionsArgs); // Create a request option

    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;


    return this.http.post(this.buildServiceUrl(location + queryString), body);
  }

  public getJwt(location: string, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    return this.http.get(this.buildServiceUrl(location + queryString));
  }
  public postToFunction(location: string, body: any, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";


    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    //let options = this.jwt();
    return this.http.post(this.buildFunctionsUrl(location + queryString), body);//, options);
  }

  public getToFunction(location: string, body: any, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";
    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    return this.http.get(this.buildFunctionsUrl(location + queryString));
  }
  public postJwt(location: string, body: any, queryStringParams?: string): Rx.Observable<Object> {
    var queryString: string = "";


    if (queryStringParams != undefined)
      queryString = "?" + queryStringParams;
    //let options = this.jwt();
    return this.http.post(this.buildServiceUrl(location + queryString), body);//, options);
  }

  private jwt() {
    // create authorization header with jwt token
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + currentUser.token });
      return headers;
      //      return new HttpRequest({ headers: headers, withCredentials: true } as HttpRequest);
    }
  }

  private optionsWithCredentials() {
    // create authorization header with jwt token
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });


    return headers;
    //return new HttpRequest({ headers: headers, withCredentials: true } as HttpRequest);
  }
}
