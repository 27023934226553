<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> About Us </h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Resources</a></li>
                                <li class="breadcrumb-item active" aria-current="page">About Us</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row align-items-center" id="counter">
            <div class="col-md-6">
                <img src="assets/images/logo-icon.png" class="img-fluid" alt="Contact Owner Logo">
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">                    
                    <div class="section-title">
                        <h4 class="title mb-4">Our Story</h4>
                        <p class="text-muted">
                            We want to enable people to do the right thing. Losing your device can be stressful. You never know when its going to happen.
                            <br /><br />
                            Our cofounders noticed that the current device locator solution has privacy concerns, is dependent on the device battery life and having the device range be within the network for it to work.
                            <br /><br />
                            <span class="text-primary fw-bold">We are inspired to provide a solution that protects the device owner's privacy, independent of the device battery life and network range.</span>
                            <br /><br />
                            Contact Owner is our answer to this concern.
                        </p>
                        <div class="mt-4">
                            <a href="https://apps.apple.com/us/app/contact-owner/id6443741876?platform=iphone" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


</section>
<!--end section-->
<!-- How This Works?-->
<section class="section bg-light" id="howthisworks">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">How <span class="text-primary">Contact Owner</span> work?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        We enable people to reach out to you if they find your lost device.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-edit d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Subscribe To Service</h5>
                        <p class="text-muted mb-0">
                            Get started by subscribing to Contact Owner subscription plan.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-link d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Register Device</h5>
                        <p class="text-muted mb-0">
                            Register your device with Contact Owner. We will create your contact link.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-chat-bubble-user d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Add Widget and Sticker</h5>
                        <p class="text-muted mb-0">
                            Add Contact Owner widget to your Today View and/or place our contact sticker on your device.
                            <br /><span class="fw-bold">** Stickers coming soon</span>
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

</section>
<!--end section-->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->