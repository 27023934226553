<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Frequently Asked Questions </h4>

          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Help Center</a></li>
                    <li class="breadcrumb-item active" aria-current="page">FAQs</li>
                </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-5 col-12 d-none d-md-block">
          <div class="rounded shadow p-4 sticky-bar">
              <ul class="list-unstyled mb-0">
                  <li>
                      <a href="javascript: void(0);" [ngxScrollTo]="'#general'"
                         class="mouse-down h6 text-dark">
                          General
                          Questions
                      </a>
                  </li>
                  <li class="mt-4">
                      <a href="javascript: void(0);" [ngxScrollTo]="'#mediapassword'"
                         class="mouse-down h6 text-dark">
                          Media Password
                          Questions
                      </a>
                  </li>
                  <li class="mt-4">
                      <a href="javascript: void(0);" [ngxScrollTo]="'#secureshare'"
                         class="mouse-down h6 text-dark">
                          Secure Share
                          Questions
                      </a>
                  </li>
                  <li class="mt-4">
                      <a href="javascript: void(0);" [ngxScrollTo]="'#support'"
                         class="mouse-down h6 text-dark">
                          Support
                          Questions
                      </a>
                  </li>
              </ul>
          </div>        
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-7 col-12">
          <div class="section-title" id="general">
              <h4>General Questions</h4>
          </div>

          <div class="faq-content mt-4 pt-3">

              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelfaq" closeOthers="true">
                  <ngb-panel id="ngb-panelfaq" cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingone">
                              <h6 class="title mb-0 text-dark"> What is <span class="text-primary">Instant Pass</span>? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Instant Pass is Media Password Manager Vault.
                              <br /><br />
                              With Instant Pass you can use videos and pictures to generate an extremely long, easy to remember, and complex password.
                              <br /><br />
                              Learn more about Instant Pass <a href="instant-pass#features" class="text-primary fw-bold">features</a>.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingthree">
                              <h6 class="title mb-0 text-dark"> What is <span class="text-primary">Media Password</span>? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Our US 11,308,200 patented technology that lets you to generate password on the fly using PIN + video or picture.
                              <br /><br />
                              Learn more about Instant Pass <a href="instant-pass#mediapassword" class="text-primary fw-bold">Media Password</a>.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingfour">
                              <h6 class="title mb-0 text-dark"> What is <span class="text-primary">Picture Password</span>? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Our US 11,308,200 patented technology that lets you to generate password on the fly using PIN + picture.
                              <br /><br />
                              Learn more about Instant Pass <a href="instant-pass#product" class="text-primary fw-bold">Media Password</a>.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingfour">
                              <h6 class="title mb-0 text-dark"> What is <span class="text-primary">Video Password</span>? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Our US 11,308,200 patented technology that lets you to generate password on the fly using PIN + video.
                              <br /><br />
                              We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingfour">
                              <h6 class="title mb-0 text-dark"> How can I <span class="text-primary">install</span> Instant Pass? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Instant Pass is available via iOS or Android mobile applications.
                              <br /><br />
                              <a href="instant-pass#product" class="text-primary fw-bold">Download</a> Instant Pass Mobile Application.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingfour">
                              <h6 class="title mb-0 text-dark"> How much does Instant Pass <span class="text-primary">cost</span>?</h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Instant Pass has three Subscription Plans: Free, Starter ($0.99/month) and Cloud ($1.99/month).
                              <br /><br />
                              <a href="instant-pass#pricing" class="text-primary fw-bold">Learn more</a> about Instant Plan Subscription Plan features.
                          </p>
                      </ng-template>
                  </ngb-panel>

              </ngb-accordion>
          </div>

          <div class="section-title mt-5" id="mediapassword">
              <h4>Media Password Questions</h4>
          </div>

          <div class="faq-content mt-4 pt-3">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelapplication" closeOthers="true">
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingtwo">
                              <h6 class="title mb-0 text-dark"> How do I <span class="text-primary">register</span> for an Instant Pass account? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Sign-up is not required for Device Vault.
                              <br/><br/>
                              To Sign-up for a Cloud Vault, you need to subscript to our Cloud Vault plan.
                              <br/>
                              1. Navigate to Media Password.
                              <br/>
                              2. Navigate to "Cloud" Vault. You should see the "Register" button if you are currently not login. Click the "Register" button.
                              <br/>
                              3. Fill-out Registration form to create an Instant Pass account.
                              <br /><br />
                              <a href="help-center#tutorials" class="text-primary fw-bold">View</a> Media Password feature Tutorials.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingtwo">
                              <h6 class="title mb-0 text-dark"> What is <span class="text-primary">Media Password feature</span> ? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Sign-up is not required. You can get started by downloading our iOS or Android mobile application to start browsing our HSA-Eligible product directory.
                              <br /><br />
                              <a href="hsa-shop#product" class="text-primary fw-bold">Download</a> our HSA-Shop Mobile Application.
                          </p>
                      </ng-template>
                  </ngb-panel>                  
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingtwo">
                              <h6 class="title mb-0 text-dark"> How do I <span class="text-primary">add a profile</span> in Instant Pass? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Sign-up is not required. You can get started by downloading our iOS or Android mobile application to start browsing our HSA-Eligible product directory.
                              <br /><br />
                              <a href="hsa-shop#product" class="text-primary fw-bold">Download</a> our HSA-Shop Mobile Application.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingtwo">
                              <h6 class="title mb-0 text-dark"> How can I <span class="text-primary">assign an image or video</span> to Instant Pass profile? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Sign-up is not required. You can get started by downloading our iOS or Android mobile application to start browsing our HSA-Eligible product directory.
                              <br /><br />
                              <a href="hsa-shop#product" class="text-primary fw-bold">Download</a> our HSA-Shop Mobile Application.
                          </p>
                      </ng-template>
                  </ngb-panel>                  
              </ngb-accordion>
          </div>

          <div class="section-title mt-5" id="secureshare">
              <h4>Secure Share Questions</h4>
          </div>

          <div class="faq-content mt-4 pt-3">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelapplication" closeOthers="true">
                  
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingtwo">
                              <h6 class="title mb-0 text-dark"> What is <span class="text-primary">Secure Sharing feature</span> ? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Secure Sharing feature lets you create an encrypted auto self-destructive message that you can send to anyone.
                              <br /><br />
                              <a href="instant-pass#app" class="text-primary fw-bold">Learn more</a> about Secure Sharing feature.
                          </p>
                      </ng-template>
                  </ngb-panel>                  
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingtwo">
                              <h6 class="title mb-0 text-dark"> How do I <span class="text-primary">create</span> a secure share link? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              1. In the Mobile Application
                              <br/>
                              2. Navigate to "Secure Sharing" and go to the "Secure Sharing Link Creator" section
                              <br/>
                              3. Fill out the Securing Sharing form
                              <br/>
                              4. Click "Create Link To Share"
                              <br /><br />
                              <a href="help-center#tutorials" class="text-primary fw-bold">View</a> Secure Sharing Tutorial.
                          </p>
                      </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingtwo">
                              <h6 class="title mb-0 text-dark"> How do I <span class="text-primary">share</span> a secure share link? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              1. Follow the instructions to create a secure share link.
                              <br />
                              2. Navigate to "Genereated Link For Secure Sharing"
                              <br />
                              3. Copy the Secure Sharing
                              <br />
                              4. Distribute this link to your recipent using email, text messages or any deliver method of choice.
                              <br /><br />
                              <a href="help-center#tutorials" class="text-primary fw-bold">View</a> Secure Sharing Tutorial.
                          </p>
                      </ng-template>
                  </ngb-panel>
              </ngb-accordion>
          </div>


          <div class="section-title mt-5" id="support">
              <h4>Support Questions</h4>
          </div>

          <div class="faq-content mt-4 pt-3">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
                  <ngb-panel id="ngb-panelsupport" cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                          <div class="border-0 bg-light" id="headingone">
                              <h6 class="title mb-0 text-dark"> I need help/have <span class="text-primary">questions</span> related to HSA-Shop ? </h6>
                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="text-muted mb-0 faq-ans">
                              Our email is <a href="mailto:contactus@thinkdiscountllc.com" class="text-primary fw-bold">contactus@thinkdiscountllc.com</a>
                              <br /><br />
                              Please reach out to us using the <a href="contact-us" class="text-primary fw-bold">Contact Us</a> form.
                          </p>
                      </ng-template>
                  </ngb-panel>

              </ngb-accordion>

          </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Have A Request ?</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="contact-us" class="btn btn-soft-primary">Submit a Request</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-question-circle d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Questions ?</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="contact-us" class="btn btn-soft-primary">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->